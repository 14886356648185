<template>
  <div class="register-container">
    <div class="register-card">
      <div class="card-container-1">
        <h1 class="register-title">欢迎注册</h1>
        <form class="register-form">


          <div class="avatar-select">
            <div v-for="(avatar, index) in avatars"
                 :key="index"
                 :class="{ 'active': selectedAvatar === index, 'large': selectedAvatar === index, 'small': selectedAvatar !== index }"
                 @click="selectAvatar(index)">
              <img :src="avatar" alt="avatar">
            </div>
          </div>


          <div class="form-group">
            <span class="label">用户名</span>
            <input type="text" placeholder="请输入用户名" autocomplete="off" v-model="form.name">
          </div>
          <div class="form-group">
            <span class="label">密码</span>
            <input type="password" placeholder="请输入密码" v-model="form.password">
          </div>
          <button class="register-button" type="button" @click="register">提交</button>
        </form>
        <div class="to-login-links">
          <a @click="toLogin" class="to-login-link">已有账号，立即登录</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Register",
    data() {
      return {
        form: {
          name: '',
          password: '',
          avatar: '/static/images/boy1.png'
        },
        avatars: [
          '/static/images/boy1.png',
          '/static/images/boy2.png',
          '/static/images/boy3.png',
          '/static/images/boy4.png',
          '/static/images/boy5.png',
          '/static/images/girl1.png',
          '/static/images/girl2.png',
          '/static/images/girl3.png',
          '/static/images/girl4.png',
          '/static/images/girl5.png',

          // '/static/images/Avatar-2.png',
          // '/static/images/Avatar-3.png',
          // '/static/images/Avatar-4.png',
        ],
        selectedAvatar: 0,
      }
    },
    created() {
    },
    methods: {
      register() {
        if (this.form.name.trim() === '') {
          console.log('请输入用户名');
          this.$message({
            showClose: true,
            message: '请输入用户名',
            type: 'error'
          });
        } else if (this.form.password.trim() === '') {
          console.log('请输入密码');
          this.$message({
            showClose: true,
            message: '请输入用户名',
            type: 'error'
          });
        } else {
          this.$http.post('/user/register', this.form).then((res) => {
            if (res.data === '注册成功') {
              this.$message({
                showClose: true,
                message: '注册成功，现在就登录吧',
                type: 'success'
              });
              this.$router.push({path: "/login"})
            } else {
              this.$message({
                showClose: true,
                message: res.data,
                type: 'error'
              });
            }
          })
        }
      },
      toLogin() {
        this.$router.push({path: './login'});
      },
      selectAvatar(index) {
        this.selectedAvatar = index;
        this.form.avatar = this.avatars[index];
      },
    }
  }
</script>

<style scoped>
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(../assets/images/background1.jpg);
    background-repeat: no-repeat;
    background-size: cover;

  }

  .register-card {
    width: 320px;
    background-color: rgba(255, 255, 255, 0.40);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    padding: 40px;
    border-radius: 15px;
    backdrop-filter: blur(5px);
  }

  .register-title {
    font-size: 35px;
    font-weight: bold;
    color: #37474F;
    margin-bottom: 30px;
  }

  .register-form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 5px 12px;
  }

  .label {
    font-size: 16px;
    font-weight: bold;
    color: #37474F;
    width: 70px;
  }

  input {
    padding: 10px;
    border: none;
    font-size: 16px;
    color: #37474F;
    flex: 1;
    background: rgba(255, 255, 255, 0);
  }

  input::placeholder {
    font-size: 15px;
    color: #37474F;
  }

  input:focus {
    outline: none;
  }

  .register-button {
    background-color: #2979FF;
    color: #FFFFFF;
    border: none;
    padding: 12px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 8px;
  }

  .register-button:hover {
    background-color: #1E88E5;
  }

  .to-login-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .to-login-link {
    font-size: 16px;
    color: black;
    text-decoration: none;
  }

  .to-login-link:hover {
    color: #2979FF;
    cursor: pointer;
  }


  .avatar-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;

  }

  .avatar-select div {

    cursor: pointer;
    border-radius: 50%;

    transition: all 0.1s ease-in-out;

    flex:0 0 calc(20%);

  }

  .avatar-select img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: 2px solid transparent;
  }

  .avatar-select .active img{
    border-radius: 50%;
    /*border: 2px solid #2979FF;*/
    outline-color: #2979FF;
    z-index: 1000;
  }

  .avatar-select .small {
    /* 当头像未被选中时，缩小它的尺寸 */
    transform: scale(0.8);
  }

  .avatar-select .large {
    /* 当头像被选中时，放大它的尺寸 */
    transform: scale(1.1);
  }

  @media screen and (max-width: 700px) {
    .card-container-1 {
      width: 100%;
    }

    .register-card {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 0;
      display: flex;
      align-items: center;
    }
  }
</style>
